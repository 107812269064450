<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-car-state-current
        :carStateCurrent="carStateCurrent"
        @editForm="editForm"
        @editPerformerCarta="editPerformerCarta"
        @editOrderProcessing="editOrderProcessing"
        @editSmsPerformer="editSmsPerformer"
        @openCallSip="openCallSip"
        @timerAfto="timerAfto"
      />
      <modal-changes
        :idCarStateCurrent="idCarStateCurrent"
        @refresh="refresh"
      />
      <modal-order-processing
        :orderProcessing="orderProcessing"
        @refresh="refresh"
      />
      <modal-send-message :clientPhone="clientPhone" />
    </div>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
  </div>
</template>

<script>
import ModalOrderProcessing from "@/views/component/Modal/ModalCarState/ModalOrderProcessing.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import ModalSendMessage from "@/views/component/Modal/ModalMessage/ModalSendMessage.vue";
import tableCarStateCurrent from "@/views/component/Table/tableCarStateCurrent.vue";
import ModalChanges from "@/views/component/Modal/ModalCarState/ModalChanges.vue";
import socket from "@/utils/socket/socket";
export default {
  components: {
    tableCarStateCurrent,
    ToastificationContent,
    ModalOrderProcessing,
    ModalSendMessage,
    ModalChanges,
  },
  data() {
    return {
      showPreloader: false,
      carStateCurrent: [],
      idCarStateCurrent: null,
      orderProcessing: null,
      clientPhone: null,
      callPerformer: null,
      timer: null,
      fields: [
        { key: "id", label: "ID" },
        { key: "division_name", label: "Подразделение" },
        { key: "performer_login", label: "Логин" },
        { key: "district", label: "Район" },
        { key: "zone_state", label: "Зона" },
        { key: "village", label: "Деревня" },
        { key: "performer_balance", label: "Баланс исполнителя" },
        { key: "perfomer_id", label: "ID исполнителя" },
        { key: "full_name_performer", label: "Ф.И.О" },
        { key: "performer_transport_model", label: "Модель исполнитель" },
        { key: "performer_transport_color", label: "Цвет исполнитель" },
        { key: "performer_transport_number", label: "Номер исполнитель" },
        { key: "performer_transport_category", label: "Категория исполнитель" },
        { key: "performer_transport_class", label: "Класс исполнитель" },
        { key: "performer_transport_dop", label: "Доп инфо" },
        { key: "performer_transport_id", label: "ID_AUTO" },
        { key: "performer_transport_count", label: "Число мест" },
        { key: "performer_lng", label: "Широта" },
        { key: "performer_lat", label: "Долгота" },
        { key: "date_time", label: "Дата" },
        { key: "performer_is_active", label: "Статус" },
      ],
    };
  },
  mounted() {
    socket.open();
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
    this.socketStateCar();
  },
  methods: {
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.carStateCurrent = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.carStateCurrent.length
          );
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    sendToParent(tableData) {
      this.carStateCurrent = tableData;
      this.$store.commit("pageData/setdataCount", this.carStateCurrent.length);
    },
    refresh() {
      this.showPreloader = true;
      this.$http
        .get("car-state/current")
        .then((res) => {
          this.carStateCurrent = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.carStateCurrent.length
          );
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    refreshIs_Active() {
      this.$http.get("car-state/current").then((res) => {
        this.carStateCurrent = res.data;
      });
    },
    timerAfto(is_active) {
      is_active
        ? (this.timer = setInterval(() => this.refreshIs_Active(), 30000))
        : clearInterval(this.timer);
    },

    editForm(id) {
      this.idCarStateCurrent = id.performer_id;
      setTimeout(this.openChanges, 0);
    },
    openChanges() {
      this.$bvModal.show("carStateChanges");
    },
    editPerformerCarta(performer_login) {
      this.$root.$emit("getPerformerCard", performer_login);
    },
    editOrderProcessing(performer_id) {
      this.orderProcessing = performer_id;
      setTimeout(this.orderPro, 0);
    },
    orderPro() {
      this.$bvModal.show("orderProcessing");
    },
    editSmsPerformer(performer_id) {
      this.$http
        .get(`car-state/${performer_id}/get-phone-performer`)
        .then((res) => {
          this.clientPhone = res.data.phone;
          setTimeout(this.timeSmsPerformer, 0);
        });
    },
    timeSmsPerformer() {
      this.$bvModal.show("messageCreateModal");
    },
    openCallSip(performer_id) {
      this.$http
        .get(`car-state/${performer_id}/get-phone-performer`)
        .then((res) => {
          this.callPerformer = res.data.phone;
          setTimeout(this.callPerformerDriver, 0);
        });
    },
    socketStateCar() {
      socket.on("admin-gram:current-car-state-performer", (data) => {
        // Event update car state, create, update
        let state = data.data;
        this.socketStateCarCurret(state);
      });
    },
    socketStateCarCurret(state) {
      this.$http.get(`car-state/current/${state.performer_id}`).then((res) => {
        let data = this.carStateCurrent.filter(
          (e) => e.performer_id !== state.performer_id
        );
        data.unshift(res.data);
        this.carStateCurrent = data;
      });
    },
    callPerformerDriver() {
      if (this.$store.state.caller.status) {
        let initiatorNumber = JSON.parse(
          localStorage.getItem("sipConfig")
        ).user;
        this.$http
          .post("/connect-driver-operator", {
            number: initiatorNumber,
            phone: this.callPerformer,
          })
          .then(() => {
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", true);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Запрос на соединение отправлен",
                icon: "XIcon",
                variant: "success",
                text: "",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ошибка",
                icon: "XIcon",
                variant: "danger",
                text: err,
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Включите SIP телефонию",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      }
    },
  },
  // created() {
  //     this.$root.$on('getPerformerСhanges', (performer_full_data) => {

  //       });

  // },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.carStateCurrent = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.carStateCurrent.length
          );
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
  destroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style></style>
