<template>
  <b-row>
    <b-col cols="12">
      <b-card class="my-card m-0">
        <b-table
          striped
          small
          id="myTables"
          bordered
          selectable
          selected-variant="primary"
          select-mode="single"
          responsive="sm"
          :items="carStateCurrent"
          :fields="fields"
          head-row-variant="secondary"
          :tbody-tr-class="rowClass"
          v-hotkey="{
            enter: editForm,
            ctrl: editPerformerCarta,
            'shift + f9': editSmsPerformer,
            'ctrl + f9': openCallSip,
            'ctrl + m': performerMap,
          }"
          @row-dblclicked="editForm"
          @row-clicked="clicked"
          @row-contextmenu="handleContextMenuEvent"
        >
          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
            </b-form-checkbox>
          </template>
          <template #cell(performer_full_name)="data">
            <span @dblclick="getPerformer(data)" class="reference-field">
              {{ data.item.performer_full_name }}
            </span>
          </template>
          <template #cell(performer_transport_model)="data">
            <span @dblclick="getDriver(data)" class="reference-field">
              {{ data.item.performer_transport_model }}
            </span>
          </template>
          <template #cell(historyState)="data">
            <span class="reference-field" @dblclick="getState(data)">
              (***)
            </span>
          </template>
          <template #cell(created_at)="data">
            {{ $moment(data.item.created_at).format("DD.MM.YYYY HH:mm") }}
          </template>
        </b-table>
        <vue-context ref="menu" class="menu">
          <li>
            <b-link class="d-flex align-items-center" @click="editForm(id)">
              <feather-icon icon="EditIcon" size="16" />
              <span class="menu_name">Изменить </span>
              <span class="menu_key">Enter</span>
            </b-link>
            <b-link
              class="d-flex align-items-center"
              @click="editPerformerCarta"
            >
              <span class="menu_name_driver">Водители</span>
              <span class="menu_key">Ctrl</span>
            </b-link>
            <b-link
              class="d-flex align-items-center"
              @click="editOrderProcessing"
            >
              <span class="menu_name_driver">Обработка заказа</span>
            </b-link>
            <b-link class="d-flex align-items-center" @click="editSmsPerformer">
              <span class="menu_name_driver">Отправить смс</span>
              <span class="menu_keyup">Shift+F9</span>
            </b-link>
            <b-link class="d-flex align-items-center" @click="openCallSip">
              <span class="menu_name_driver">Позвонить</span>
              <span class="menu_keyup_call">Ctrl+F9</span>
            </b-link>
            <b-link class="d-flex align-items-center" @click="performerMap">
              <span class="menu_name_driver">Показать на карте</span>
              <span class="menu_keyup_map">Ctrl+M</span>
            </b-link>
            <hr />
            <b-link class="d-flex align-items-center" @click="autoUpdate">
              <img :src="iconChect" />
              <span class="menu_name_driver_afto">Автообновление</span>
            </b-link>
            <b-link class="d-flex align-items-center" @click="getState">
              <feather-icon icon="InboxIcon" size="16" />
              <span class="menu_name_driver_afto">История изменений</span>
              <span class="menu_keyup_history">F11</span>
            </b-link>
          </li>
        </vue-context>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import VueContext from "vue-context";
export default {
  components: {
    VueContext,
    FeatherIcon,
  },
  props: ["carStateCurrent"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "Флаг" },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "zone",
          label: "Зона",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "district",
          label: "Район",
          sortable: true,
          thStyle: { width: "250px" },
        },
        {
          key: "village",
          label: "Населенный пункт",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_balance",
          label: "Баланс",
          sortable: true,
          thStyle: { width: "150px" },
        },
        // { key: '', label: 'Простой(мин)', sortable: true, thStyle: { width: '150px' } },
        {
          key: "state_status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_full_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_transport_model",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_color",
          label: "Цвет авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_number",
          label: "Номер авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_category",
          label: "Категория авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_class",
          label: "Категория класс",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_dop_info",
          label: "Доп.инфо",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "historyState",
          label: "История состояний",
          sortable: true,
          thStyle: { width: "150px" },
        },
        { key: "id", label: "ID", sortable: true, thStyle: { width: "150px" } },
        {
          key: "performer_id",
          label: "ID_DRIVER",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_id",
          label: "ID_AUTO",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_lng",
          label: "Широта",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_lat",
          label: "Долгота",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_transport_count_seat",
          label: "Число мест",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "created_at",
          label: "Дата",
          sortable: true,
          thStyle: { width: "150px" },
        },
        // { key: '', label: 'Состаяние GPS', sortable: true, thStyle: { width: '150px' } },
        // { key: '', label: 'Актуалный GPS', sortable: true, thStyle: { width: '150px' } },
      ],
      id: [],
      is_active: false,
      iconChect: require("@/assets/images/icons/square.svg"),
      iconsSquare: require("@/assets/images/icons/square.svg"),
      iconsCheck: require("@/assets/images/icons/checkSquareIcon.svg"),
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    editForm() {
      this.$emit("editForm", this.id);
    },
    editPerformerCarta() {
      let performer_login = this.id.performer_login;
      this.$emit("editPerformerCarta", performer_login);
    },
    editOrderProcessing() {
      let performer_id = this.id.performer_id;
      this.$emit("editOrderProcessing", performer_id);
    },
    editSmsPerformer() {
      let performer_id = this.id.performer_id;
      this.$emit("editSmsPerformer", performer_id);
    },
    openCallSip() {
      let performer_id = this.id.performer_id;
      this.$emit("openCallSip", performer_id);
    },
    autoUpdate() {
      this.iconChect = this.is_active ? this.iconsSquare : this.iconsCheck;
      this.is_active = !this.is_active;
      this.$emit("timerAfto", this.is_active);
    },
    performerMap() {
      let performer = this.id;
      this.$router.push({
        name: "performer-map",
        query: { filter: performer, text: "(текущее)" },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Местоположения водителя " + performer.performer_id,
        `/performer-map`,
        performer,
      ]);
    },
    getPerformer(data) {
      this.$router.push({
        name: "driver-performer",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer_id,
        `/driver-performer`,
        data.item.performer_id,
      ]);
    },
    getDriver(data) {
      this.$router.push({
        name: "car-performer",
        query: { filter: data.item.performer_transport_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Автомобиля " + data.item.performer_transport_id,
        `/car-performer`,
        data.item.performer_transport_id,
      ]);
    },
    getState() {
      let performer_id = this.id.performer_id;
      this.$router.push({
        name: "history-state",
        query: { filter: performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "История состояний " + performer_id,
        `/history-state`,
        performer_id,
      ]);
    },
    clicked(item, index, event) {
      this.id = item;
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item;
    },
    rowClass(data) {
      return data.color;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
.menu {
  width: 250px;
}
.menu_key {
  margin-left: 92px;
}
.menu_name {
  margin-left: 10px;
}
.menu_keyup {
  margin-left: 35px;
}
.menu_keyup_call {
  margin-left: 70px;
}
.menu_keyup_history {
  margin-left: 20px;
}
.menu_keyup_map {
  margin-left: 25px;
}
.menu_name_driver {
  margin-left: 25px;
}
.menu_name_driver_afto {
  margin-left: 9px;
}
</style>
